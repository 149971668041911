import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Loader from '../ui/Loader/Loader';

const PrivateRoute = ({ children }) => {
  const { user, isAuthenticated, loader } = useAuth();
  if (loader) {
    return <Loader />;
  }
  if (user) {
  }
  return isAuthenticated ? children : <Navigate to="/app/login" />;
};

export default PrivateRoute;
