import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const addExternalScript = (url, attributes = {}) => {
  const script = document.createElement('script');
  script.src = url;
  script.defer = true;
  Object.keys(attributes).forEach((key) => {
    script.setAttribute(key, attributes[key]);
  });
  document.body.appendChild(script);
};

const domain = process.env.REACT_APP_PLAUSIBLE_DOMAIN;
addExternalScript('https://plausible.io/js/script.js', {
  'data-domain': domain,
});

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
