import React from 'react';

const TinyLoader = () => {
  return (
    <button className="btn btn-square bg-[#FDCBFB]">
      <span className="loading loading-spinner"></span>
    </button>
  );
};

export default TinyLoader;
