import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import ClientPage from './components/ClientPage/ClientPage';
import SignupPage from './components/SIgnupPage/SignupPage';
import LoginPage from './components/LoginPage/LoginPage';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Loader from './components/ui/Loader/Loader';
import PublicRoute from './components/PublicRoute/PublicRoute';
import EmptyState from './components/ui/EmptyState/EmptyState';
import LandingPage from './components/LandingPage/LandingPage';
import TOS from './components/TOSPage/TOSPage';
import TOSPage from './components/TOSPage/TOSPage';
import PrivacyPage from './components/PrivacyPage/PrivacyPage';
import RefundPolicyPage from './components/RefundPolicyPage/RefundPolicyPage';
const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/help/tos" element={<TOSPage />} />
          <Route path="/help/privacy" element={<PrivacyPage />} />
          <Route path="/help/refundpolicy" element={<RefundPolicyPage />} />
          <Route
            path="/app/signup"
            element={
              <PublicRoute>
                <SignupPage />
              </PublicRoute>
            }
          />
          <Route
            path="/app/login"
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path="/app/dashboard"
            element={
              <PrivateRoute>
                <ClientPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/empty"
            element={
              <PrivateRoute>
                <EmptyState />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
