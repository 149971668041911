import React, { useState } from 'react';
import axios from 'axios';
import TinyLoader from '../TinyLoader/TinyLoader';

function SingleInputForm({
  title,
  displayText,
  buttonName,
  handleSubmit,
  buttonClickStatus,
}) {
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const buttonClickHandler = (event) => {
    event.preventDefault();
    setShowLoader(true);
    handleSubmit(email);
  };

  return (
    <div>
      <div className="hero">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <div className="text-center lg:text-left">
            <h1 className="text-5xl font-bold">{title}</h1>
            <p className="py-6">
              {displayText}{' '}
              {(displayText ===
                'got upset 😭 since she could not find you in our records, please signup by clicking' ||
                displayText ===
                  'found you in our records but observed that your email is not verified 😔, please verify yourself by signing up again') && (
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/signup`}
                  style={{ fontWeight: 'bold', color: '#ff6347' }}
                >
                  here
                </a>
              )}
              {displayText ===
                "found that you're already registered with featureNest✅ , so you should rather try to login by clicking" && (
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/login`}
                  style={{ fontWeight: 'bold', color: '#ff6347' }}
                >
                  here
                </a>
              )}
            </p>
          </div>
          {!buttonClickStatus && (
            <div className="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
              <form className="card-body" onSubmit={buttonClickHandler}>
                <div className="form-control">
                  <label className="label"></label>
                  <input
                    type="email"
                    placeholder="email"
                    className="input input-bordered"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {!showLoader ? (
                  <div className="form-control mt-6">
                    <button className="btn bg-[#fb7185] text-black">
                      {buttonName}
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center justify-center mt-6">
                    <TinyLoader />
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleInputForm;
