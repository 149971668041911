import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import styles from './TinyBanner.module.css';
import Tooltip from '../Tooltip/Tooltip';

const TinyBanner = ({ projectId }) => {
  const handleCopyToClipboard = async () => {
    //const projectId = '66810fadac5c4fd63efefd0c';
    try {
      await navigator.clipboard.writeText(projectId);
    } catch (err) {}
  };

  return (
    <div className={`stats bg-[#FDD8CB] shadow ${styles.tinyBanner}`}>
      <div className="stat">
        <div className="stat-title flex justify-between items-center font-bold text-gray-800">
          <span>📕 project-id</span>
          <Tooltip message="copy">
            <button className="ml-2">
              <FontAwesomeIcon
                icon={faClipboard}
                className="text-gray-600 hover:text-gray-800"
                onClick={handleCopyToClipboard}
              />
            </button>
          </Tooltip>
        </div>
        <div className="stat-desc text-gray-800 mt-2">
          <input
            type="text"
            value={projectId}
            readOnly
            className="w-full bg-[#D6FDCB] text-gray-800 border border-solid border-gray-400 p-2 rounded-md focus:outline-none"
          />
        </div>
      </div>
    </div>
  );
};

export default TinyBanner;
