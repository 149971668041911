import React from 'react';

const RefundPolicyPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-[#fcf2f4]">
      <header className="flex items-center justify-between px-4 lg:px-6 h-16 bg-white border-b">
        <a className="flex items-center" href="#">
          <h3 className="text-2xl font-bold text-gray-800">featureNest.co</h3>
        </a>
        <nav className="hidden lg:flex gap-4 sm:gap-6">
          <a
            class="text-sm font-medium hover:underline underline-offset-4"
            href="/"
          >
            Home
          </a>
        </nav>
      </header>
      <div className="max-w-3xl mx-auto py-12">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-900">
          Refund Policy
        </h1>
        <p className="text-gray-600 text-center mb-8">
          Last Updated: 27/07/2024
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            1. Introduction
          </h2>
          <p className="text-gray-700 leading-relaxed">
            Thank you for choosing featureNest. We want you to be satisfied with
            our services. If you are not entirely satisfied, we're here to help.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            2. Subscription
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Subscription Fees:</strong> Please buy subscription when
              fully satisfied by our services. If you subscribe for a month ,
              that fees would be non-refundable. But you can cancel your
              subscription for the next month.
            </li>
            <li>
              <strong>Subscription Cancellation:</strong> Subscriptions can be
              cancelled only for the next cycle.
            </li>
          </ul>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            3. Changes to this Policy
          </h2>
          <p className="text-gray-700 leading-relaxed">
            We may update this Refund Policy from time to time. We will notify
            you of any changes by posting the new policy on our website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            4. Contact Us
          </h2>
          <p className="text-gray-700 leading-relaxed">
            If you have any questions about this Refund Policy, please contact
            us at{' '}
            <a
              href="mailto:masakali@featurenest.co"
              className="text-blue-500 hover:underline"
            >
              masakali@featurenest.co
            </a>
            .
          </p>
        </section>
      </div>
      <footer className="flex flex-col gap-2 sm:flex-row  w-full items-center px-4 md:px-6 bg-white border-t border-gray-200 py-6 mt-auto">
        <p className="text-xs text-gray-600">
          © 2024 featureNest.co &nbsp; All rights reserved.
        </p>
        <nav class="sm:ml-auto flex gap-4 sm:gap-6">
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/tos"
          >
            Terms and Conditions
          </a>
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/privacy"
          >
            Privacy Policy
          </a>
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/refundpolicy"
          >
            Refund Policy
          </a>
        </nav>
      </footer>
    </div>
  );
};

export default RefundPolicyPage;
