import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareCheck,
  faTrash,
  faRing,
  faClock,
  faTrashArrowUp,
  faTrashCanArrowUp,
  faCalendarDays,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import styles from './Collapse.module.css';
import axios from 'axios';
import axiosInstance from '../../../services/api/axiosInstance';
import Tooltip from '../Tooltip/Tooltip';
import TinyLoader from '../TinyLoader/TinyLoader';
import SendMailModal from '../SendMailModal/SendMailModal';

const Collapse = ({ feature, onCategoryUpdate, categoryPage, projectName }) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCategoryChange = async (type) => {
    try {
      setLoading(true);
      const config = {
        method: 'patch',
        url: `/featureRequest/${feature._id}?category=${type}`,
      };
      const updateResponse = await axiosInstance.request(config);
      onCategoryUpdate();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <TinyLoader />
      </div>
    );
  }
  return (
    <div
      tabIndex={0}
      className="collapse collapse-arrow border border-base-100 bg-[#f8fafc]"
      style={{ width: '700px' }}
    >
      <div className="collapse-title text-xl font-medium">
        <div className="flex justify-between items-center">
          <div className="badge text-[#991b1b] bg-[#e0e7ff] font-bold">
            {feature.frontendId}
          </div>
          <div className="badge bg-[#E6FDD7] text-xs">
            <FontAwesomeIcon
              icon={faCalendarDays}
              size="lg"
              style={{ color: '#FCBDEE' }}
              className="mr-2"
            />
            {feature.createdAt}
          </div>
        </div>
        <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
          <p className="text-sm mt-2">{feature.text}</p>
        </div>
        {/* <p className="text-sm  mt-2">{feature.text}</p> */}
      </div>

      <div className={`collapse-content ${styles.iconRight}`}>
        {categoryPage === 'pending' && (
          <Tooltip message="Ⓜ️ove to valuable" placement="top">
            <div
              onClick={() => handleCategoryChange('valuable')}
              className="cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faRing}
                size="lg"
                style={{ color: '#FFD700' }}
              />
            </div>
          </Tooltip>
        )}
        {(categoryPage === 'pending' || categoryPage === 'valuable') && (
          <Tooltip message="Ⓜ️ove to future" placement="top">
            <div
              onClick={() => handleCategoryChange('future')}
              className="cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faClock}
                size="lg"
                style={{ color: '#1eb0ff63' }}
              />
            </div>
          </Tooltip>
        )}
        {(categoryPage === 'pending' ||
          categoryPage === 'valuable' ||
          categoryPage === 'future') && (
          <Tooltip message="Ⓜ️ove to done" placement="top">
            <div
              onClick={() => handleCategoryChange('done')}
              className="cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faSquareCheck}
                size="lg"
                style={{ color: '#63E6BE' }}
              />
            </div>
          </Tooltip>
        )}
        {(categoryPage === 'pending' ||
          categoryPage === 'valuable' ||
          categoryPage === 'future' ||
          categoryPage === 'done') && (
          <Tooltip message="Ⓜ️ove to trash" placement="top">
            <div
              onClick={() => handleCategoryChange('trash')}
              className="cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faTrash}
                size="lg"
                style={{ color: '#808080' }}
              />
            </div>
          </Tooltip>
        )}
        {categoryPage === 'trash' && (
          <Tooltip message="restore to pending" placement="top">
            <div
              onClick={() => handleCategoryChange('pending')}
              className="cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faTrashCanArrowUp}
                size="lg"
                style={{ color: '#5DBBFC' }}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <div
        className={`collapse-content flex items-center justify-between ${styles.standaloneIcon}`}
      >
        <Tooltip message="send mail" placement="right">
          <div
            className="cursor-pointer w-4 h-4 flex items-center justify-center p-0 bg-transparent border-none"
            onClick={(e) => {
              // e.stopPropagation();
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              size="lg"
              style={{ color: '#F9A0DF' }}
            />
          </div>
        </Tooltip>
      </div>
      {isModalOpen && (
        <SendMailModal
          clientId={123}
          onProjectCreation={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          featureRequest={feature}
          projectName={projectName}
        />
      )}
    </div>
  );
};

export default Collapse;
