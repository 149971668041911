import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ message, children, placement = 'left' }) => {
  const getTooltipPosition = () => {
    switch (placement) {
      case 'top':
        return 'bottom-full mb-1';
      case 'bottom':
        return 'top-full mt-1';
      case 'left':
        return 'right-full mr-1 top-1/2 transform -translate-y-1/2';
      case 'right':
        return 'left-full ml-1 top-1/2 transform -translate-y-1/2';
      default:
        return 'top-full mt-1';
    }
  };

  return (
    <div className="relative group inline-block">
      {children}
      <div
        className={`absolute ${getTooltipPosition()} hidden group-hover:flex flex-col items-center z-999`}
      >
        <div className="bg-gray-700 text-white text-xs rounded py-1 px-2 whitespace-no-wrap z-999">
          {message}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
