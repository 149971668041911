import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import FormErrorMessage from '../FormErrorMessage/FormErrorMessage';
import axiosInstance from '../../../services/api/axiosInstance';

const Modal = ({ clientId, onProjectCreation }) => {
  const [projectName, setProjectName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitDisable, setSubmitDisable] = useState(false);

  const handleInputChange = (event) => {
    const inputText = event.target.value.slice(0, 25);
    setProjectName(inputText);
    if (event.target.value.trim().length === 0) {
      setErrorMessage('project name cannot be empty');
      setSubmitDisable(true);
    } else if (event.target.value.length > 25) {
      setErrorMessage('project name cannot exceed more than 25 characters');
    } else {
      setErrorMessage('');
      setSubmitDisable(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = JSON.stringify({
        projectName,
      });
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `/clients/${clientId}/projects`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      await axiosInstance.request(config);
      onProjectCreation();
    } catch (err) {
      console.error(err);
    } finally {
      document.getElementById('my_modal_1').close();
      setProjectName('');
      setErrorMessage('');
      setSubmitDisable(false);
    }
  };

  const handleClose = (event) => {
    event.preventDefault();
    setProjectName('');
    setErrorMessage('');
    setSubmitDisable(false);
    document.getElementById('my_modal_1').close();
  };

  return (
    <>
      <button
        className="btn w-12 h-12 rounded-full bg-[#D6F9CE] text-white flex items-center justify-center"
        onClick={() => document.getElementById('my_modal_1').showModal()}
      >
        <FontAwesomeIcon icon={faPlus} size="lg" style={{ color: 'black' }} />
      </button>
      <dialog id="my_modal_1" className="modal">
        <div className="modal-box">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h3 className="font-bold text-lg">new project</h3>
            <button
              onClick={handleClose}
              className="btn w-12 h-4 bg-[#CBF2FD] text-black"
              style={{ marginLeft: 'auto' }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                size="2xl"
                style={{ color: 'black' }}
              />
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="input input-bordered w-full mt-4"
              placeholder="enter project name"
              value={projectName}
              onChange={handleInputChange}
              required
            />
            {errorMessage && <FormErrorMessage message={errorMessage} />}
            <div className="modal-action">
              <button
                type="submit"
                className="btn bg-[#fb7185] text-black"
                disabled={submitDisable}
              >
                submit
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </>
  );
};

export default Modal;
