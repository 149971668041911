import React, { useEffect } from 'react';

const VideoModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (!isOpen) return;

    const handleOutsideClick = (e) => {
      if (e.target.classList.contains('video-modal-overlay')) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="video-modal-overlay fixed inset-0 z-50 flex items-center justify-center">
      <div className="relative bg-white rounded-lg shadow-lg overflow-hidden">
        <iframe
          width="800"
          height="450"
          src="https://www.youtube.com/embed/9mYO456nGHw?autoplay=1"
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="block"
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
