import React from 'react';
import { useAuth } from '../../context/AuthContext';
import Loader from '../ui/Loader/Loader';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const { user, isAuthenticated, loading } = useAuth();
  if (loading) {
    return <Loader />;
  }

  return isAuthenticated ? <Navigate to="/app/dashboard" /> : children;
};

export default PublicRoute;
