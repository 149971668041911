import React, { useState } from 'react';
import axios from 'axios';
import SingleInputForm from '../ui/SingleInputForm/SingleInputForm';
import Footer from '../ui/Footer/Footer';
import styles from './SignupPage.module.css';

const SignupPage = () => {
  const [title, setTitle] = useState('get started!');
  const [displayText, setDisplayText] = useState(
    '🕊 Masakali from featureNest would like to know your email'
  );
  const [buttonName, setButtonName] = useState('submit');
  const [buttonClickStatus, setButtonClickStatus] = useState(false);

  const handleButtonSubmit = async (email) => {
    const data = JSON.stringify({
      email,
    });
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/registerUser`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
      setTitle('🕊 Masakali! ');
      setDisplayText(
        `sent you an email on ${email} with a link to verify yourself on our platform. please check your SPAM folder as well`
      );
      //setEmail('');
      setButtonClickStatus(true);
    } catch (err) {
      if (err.response.data.error.message === 'internal-server-error') {
        setTitle('🕊 Masakali! ');
        setDisplayText(
          `messed up a bit 😔, this was not supposed to happen. please try again after sometime`
        );
        setButtonClickStatus(true);
      }
      if (
        err.response.data.error.message ===
        'already-registered, Please try logging in!'
      ) {
        setTitle('🕊 Masakali! ');
        setDisplayText(
          `found that you're already registered with featureNest✅ , so you should rather try to login by clicking`
        );
        setButtonClickStatus(true);
      }
    }
  };

  return (
    <div className={styles.signupPage}>
      <main className={styles.mainContent}>
        <SingleInputForm
          title={title}
          displayText={displayText}
          buttonName={buttonName}
          handleSubmit={handleButtonSubmit}
          buttonClickStatus={buttonClickStatus}
        />
      </main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </div>
  );
};

export default SignupPage;
