import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import FormErrorMessage from '../FormErrorMessage/FormErrorMessage';
import axiosInstance from '../../../services/api/axiosInstance';
import { useAuth } from '../../../context/AuthContext';
import TinyLoader from '../TinyLoader/TinyLoader';

const SendMailModal = ({ isOpen, onClose, featureRequest, projectName }) => {
  const { user } = useAuth();
  const [emailId, setEmailId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitDisable, setSubmitDisable] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const inputText = event.target.value.slice(0, 25);
    setEmailId(inputText);
    if (event.target.value.trim().length === 0) {
      setErrorMessage('email-id cannot be empty');
      setSubmitDisable(true);
    } else if (event.target.value.length > 25) {
      setErrorMessage('Email id cannot exceed more than 25 characters');
    } else {
      setErrorMessage('');
      setSubmitDisable(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const data = JSON.stringify({
        email: emailId,
        featureRequest: featureRequest.text,
        date: featureRequest.createdAt,
        projectName,
        userEmail: user.email,
      });
      await axiosInstance.post(`/share`, data, {
        headers: { 'Content-Type': 'application/json' },
        baseURLOverride: process.env.REACT_APP_AUTH_SERVICE_CONTACT_URL,
      });
      setApiResponse('🕊Masakali shared your feature request ✅');
    } catch (err) {
      setApiResponse('🕊Masakali could not share your feature request 😔');

      console.error(err);
    } finally {
      setLoading(false);
      setEmailId('');
      setErrorMessage('');
      setSubmitDisable(false);
    }
  };

  return (
    isOpen && (
      <>
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        ></div>
        <dialog open className="modal z-50">
          <div className="modal-box">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h3 className="font-bold text-lg">send 📧 to team-mate</h3>
              <button
                onClick={onClose}
                className="btn w-12 h-4 bg-[#CBF2FD] text-black"
                style={{ marginLeft: 'auto' }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="2xl"
                  style={{ color: 'black' }}
                />
              </button>
            </div>
            {!apiResponse ? (
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  className="input input-bordered w-full mt-4"
                  placeholder="enter email id"
                  value={emailId}
                  onChange={handleInputChange}
                  required
                />
                {errorMessage && <FormErrorMessage message={errorMessage} />}
                {!loading ? (
                  <div className="modal-action">
                    <button
                      type="submit"
                      className="btn bg-[#fb7185] text-black"
                      disabled={submitDisable}
                    >
                      send
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <TinyLoader />
                  </div>
                )}
              </form>
            ) : (
              <h3 className="font-bold text-lg">{apiResponse}</h3>
            )}
          </div>
        </dialog>
      </>
    )
  );
};

export default SendMailModal;
