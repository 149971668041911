import React, { useState, useEffect } from 'react';
import styles from './Navbar.module.css';
import axios from 'axios';
import logo from '../../../assets/name.png';

const Navbar = ({ projectList, onDropdownSelect }) => {
  const [selectedProject, setSelectedProject] = useState({});
  const [key, setKey] = useState(0);

  const handleProjectSelection = (project) => {
    setSelectedProject(project);
    setKey((prev) => prev + 1);
    onDropdownSelect(project);
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_AUTH_SERVICE_URL}/logOutUser`,
        {},
        {
          withCredentials: true,
        }
      );
      localStorage.removeItem('sessionToken');
      window.location.href = '/app/login';
    } catch (err) {
      console.error('logout-error', err);
    }
  };

  useEffect(() => {
    if (projectList.length > 0) {
      handleProjectSelection(projectList[0]);
    }
  }, [projectList]);

  return (
    <div className="navbar bg-[#c7d2fe]">
      <div className="navbar-start">
        <a href="/">
          <img
            src={logo}
            alt="FeatureNest Logo"
            className="logo-image"
            style={{ width: '200px', height: 'auto' }}
          />
        </a>
      </div>
      {projectList.length > 0 && (
        <div className="navbar-center hidden lg:flex">
          <ul className={`${styles.dropdownMenu} menu menu-horizontal px-1`}>
            <li>
              <details key={key}>
                <summary className={`${styles.boldText} hover:bg-[#E8DAEF]`}>
                  {selectedProject.projectName}
                </summary>
                <ul className={`${styles.dropdownMenu} p-2`}>
                  {projectList.map((project, index) => (
                    <li>
                      <a onClick={() => handleProjectSelection(project)}>
                        {project.projectName}
                      </a>
                    </li>
                  ))}
                </ul>
              </details>
            </li>
          </ul>
        </div>
      )}
      <div className="navbar-end">
        <a className="btn bg-[#fb7185] text-black" onClick={handleLogout}>
          logout
        </a>
      </div>
    </div>
  );
};

export default Navbar;
