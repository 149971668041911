import React from 'react';

const PrivacyPage = () => {
  return (
    <div className="flex flex-col min-h-[100dvh] bg-[#fcf2f4]">
      <header className="flex items-center justify-between px-4 lg:px-6 h-16 bg-white border-b">
        <a className="flex items-center" href="#">
          <h3 className="text-2xl font-bold text-gray-800">featureNest.co</h3>
        </a>
        <nav className="hidden lg:flex gap-4 sm:gap-6">
          <a
            class="text-sm font-medium hover:underline underline-offset-4"
            href="/"
          >
            Home
          </a>
        </nav>
      </header>
      <div className="max-w-3xl mx-auto py-12">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-900">
          Privacy Policy
        </h1>
        <p className="text-gray-600 text-center mb-8">
          Last Updated: 27/07/2024
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            1. Introduction
          </h2>
          <p className="text-gray-700 leading-relaxed">
            At featureNest, we are committed to protecting your privacy. This
            Privacy Policy explains how we collect, use, and share information
            about you when you use our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            2. Information We Collect
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Account Information:</strong> When you create an account,
              email address.
            </li>
            <li>
              <strong>Payment Information:</strong> We use Paddle to process
              payments. We do not store your payment information on our servers
              like Credit Card Numbers etc.
            </li>
            <li>
              <strong>Feature Requests:</strong> Information related to feature
              requests submitted through our widget.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            3. How We Use Your Information
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Provide Services:</strong> To provide and improve our
              services.
            </li>
            <li>
              <strong>Communications:</strong> To communicate with you about
              your account and our services.
            </li>
            <li>
              <strong>Billing:</strong> To process payments and manage
              subscriptions.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            4. Sharing Your Information
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Service Providers:</strong> We may share your information
              with third-party service providers who perform services on our
              behalf.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We may disclose your
              information if required by law or in response to a legal process.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            5. Data Security
          </h2>
          <p className="text-gray-700 leading-relaxed">
            We implement reasonable security measures to protect your
            information. However, no security system is impenetrable, and we
            cannot guarantee the security of your information.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            6. Your Choices
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Account Information:</strong> You can update or delete
              your account information at any time.
            </li>
            <li>
              <strong>Marketing Communications:</strong> You can opt-out of
              receiving marketing communications from us.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            7. Changes to this Policy
          </h2>
          <p className="text-gray-700 leading-relaxed">
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            8. Contact Us
          </h2>
          <p className="text-gray-700 leading-relaxed">
            If you have any questions about this Privacy Policy, please contact
            us at{' '}
            <a
              href="mailto:masakali@featurenest.co"
              className="text-blue-500 hover:underline"
            >
              masakali@featurenest.co
            </a>
            .
          </p>
        </section>
      </div>

      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full items-center px-4 md:px-6 border-t bg-white">
        <p className="text-xs text-gray-600">
          © 2024 featureNest.co All rights reserved.
        </p>
        <nav class="sm:ml-auto flex gap-4 sm:gap-6">
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/tos"
          >
            Terms and Conditions
          </a>
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/privacy"
          >
            Privacy Policy
          </a>
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/refundpolicy"
          >
            Refund Policy
          </a>
        </nav>
      </footer>
    </div>
  );
};

export default PrivacyPage;
