import React from 'react';

const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full h-[100vh]">
      <img
        className="w-20 h-20 animate-spin"
        src="https://www.svgrepo.com/show/396464/feather.svg"
        alt="Loading icon"
      />
    </div>
  );
};

export default Loader;
