import React from 'react';

const Pagination = ({ page, onPageFlip, maxPage }) => {
  const handlePageFlip = (direction) => {
    onPageFlip(direction);
  };
  return (
    <div className="join">
      <button
        className="join-item btn bg-[#FCF1FF]"
        onClick={() => {
          handlePageFlip('back');
        }}
        disabled={page === 1}
      >
        «
      </button>
      <button className="join-item btn bg-[#FCF1FF]">{page}</button>
      <button
        className="join-item btn bg-[#FCF1FF]"
        onClick={() => {
          handlePageFlip('forward');
        }}
        disabled={page === maxPage}
      >
        »
      </button>
    </div>
  );
};

export default Pagination;
