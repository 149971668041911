import React from 'react';

const TOSPage = () => {
  return (
    <div className="flex flex-col min-h-[100dvh] bg-[#fcf2f4]">
      <header className="flex items-center justify-between px-4 lg:px-6 h-16 bg-white border-b">
        <a className="flex items-center" href="#">
          <h3 className="text-2xl font-bold text-gray-800">featureNest.co</h3>
        </a>
        <nav className="hidden lg:flex gap-4 sm:gap-6">
          <a
            class="text-sm font-medium hover:underline underline-offset-4"
            href="/"
          >
            Home
          </a>
        </nav>
      </header>
      <div className="max-w-3xl mx-auto py-12  ">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-900">
          Terms and Conditions
        </h1>
        <p className="text-gray-600 text-center mb-8">
          Last Updated: 27/07/2024
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            1. Introduction
          </h2>
          <p className="text-gray-700 leading-relaxed">
            Welcome to featureNest. These Terms and Conditions ("Terms") govern
            your use of our website and services. By accessing or using
            featureNest, you agree to comply with and be bound by these Terms.
            If you do not agree to these Terms, please do not use our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            2. Accounts
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Account Creation:</strong> Users can create an account to
              access the full functionality of featureNest.
            </li>
            <li>
              <strong>Free Trial:</strong> New users are offered a free 10-day
              trial period.
            </li>
            <li>
              <strong>Account Information:</strong> You agree to provide
              accurate, current, and complete information during the
              registration process and update such information to keep it
              accurate, current, and complete.
            </li>
            <li>
              <strong>Account Security:</strong> You are responsible for
              safeguarding your account and for all activities that occur under
              your account.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            3. Subscriptions
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Opting for Subscriptions:</strong> After the trial period,
              users can opt for a subscription to continue using the service.
            </li>
            <li>
              <strong>Subscription Management:</strong> Users can manage their
              subscription through their account dashboard.
            </li>
            <li>
              <strong>Cancellation:</strong> Users can cancel their subscription
              at any time. Cancellations will be effective at the end of the
              current billing cycle.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            4. Payments
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Payment Processor:</strong> We use Paddle to process all
              payments.
            </li>
            <li>
              <strong>Payment Information:</strong> You must provide accurate
              and complete payment information. By submitting payment details,
              you confirm that you have the right to use the payment method.
            </li>
            <li>
              <strong>Billing:</strong> Subscription fees will be billed in
              advance on a recurring basis as per the chosen subscription plan.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            5. Feature Requests and Client Dashboard
          </h2>
          <ul className="list-disc space-y-2 pl-6 text-gray-700 leading-relaxed">
            <li>
              <strong>Client Dashboard:</strong> Clients can view and manage
              feature requests raised by their customers.
            </li>
            <li>
              <strong>Widget Integration:</strong> Clients can integrate our
              widget into their applications to allow customers to send feature
              requests.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            6. Contact
          </h2>
          <p className="text-gray-700 leading-relaxed">
            For any inquiries, please contact us at{' '}
            <a
              href="mailto:masakali@featurenest.co"
              className="text-blue-500 hover:underline"
            >
              masakali@featurenest.co
            </a>
            .
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            7. Changes to Terms
          </h2>
          <p className="text-gray-700 leading-relaxed">
            We reserve the right to modify these Terms at any time. Changes will
            be posted on our website and will take effect immediately upon
            posting.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            8. Governing Law
          </h2>
          <p className="text-gray-700 leading-relaxed">
            These Terms are governed by and construed in accordance with the
            laws of India, without regard to its conflict of law principles.
          </p>
        </section>
      </div>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full items-center px-4 md:px-6 border-t bg-white">
        <p className="text-xs text-gray-600">
          © 2024 featureNest.co All rights reserved.
        </p>
        <nav class="sm:ml-auto flex gap-4 sm:gap-6">
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/tos"
          >
            Terms and Conditions
          </a>
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/privacy"
          >
            Privacy Policy
          </a>
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/refundpolicy"
          >
            Refund Policy
          </a>
        </nav>
      </footer>
    </div>
  );
};

export default TOSPage;
