import React from 'react';

const FormErrorMessage = ({ message }) => {
  return (
    <div style={{ color: '#8B0000', marginTop: '5px', fontSize: '12px' }}>
      {message}
    </div>
  );
};

export default FormErrorMessage;
