import React from 'react';

const EmptyState = ({ image, mainHeading, subHeading }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center p-4">
      <h1 className="text-2xl font-bold mb-2">{mainHeading}</h1>
      <p className="text-gray-600 mb-4">{subHeading}</p>
      <img
        src={
          image ||
          'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg'
        }
        alt="Empty State"
        className="w-1/3 mb-4"
      />
    </div>
  );
};

export default EmptyState;
