import React, { useState, useEffect } from 'react';

const staticContent = [
  { color: '#1e90ff', text: 'pending' },
  { color: '#FFD700', text: 'valuable' },
  { color: '#1eb0ff63', text: 'future' },
  { color: '#228B22', text: 'done' },
  { color: '#808080', text: 'trash' },
];

const List = ({ filterNumbers, projectId, onCategorySelect }) => {
  const filterList = staticContent.map((item, index) => ({
    ...item,
    count: filterNumbers[index],
  }));

  const [selectedCategory, setSelectedCategory] = useState(filterList[0].text);

  const handleFilterItemSelect = (selectedItem) => {
    onCategorySelect(selectedItem);
    setSelectedCategory(selectedItem.text);
  };
  useEffect(() => {
    setSelectedCategory(selectedCategory);
  }, [filterNumbers]);

  useEffect(() => {
    setSelectedCategory(filterList[0].text);
  }, [projectId]);

  return (
    <>
      <ul
        className="menu bg-[#f8fafc] rounded-box"
        style={{ width: '15.25rem' }}
      >
        <li className="menu-title">FILTER</li>
        {filterList.map((item, index) => (
          <li>
            <a
              onClick={() => {
                handleFilterItemSelect(item);
              }}
              className={`${
                item.text === selectedCategory ? 'bg-gray-200 font-bold' : ''
              }`}
            >
              <span
                className={`badge badge-xs text-left`}
                style={{ backgroundColor: item.color, marginRight: '8px' }}
              ></span>
              {item.text}
              <span className={`badge badge-sm`}>{item.count}</span>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default List;
