import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loader, setLoader] = useState(true);

  const extractUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_SERVICE_URL}/loggedInUser`,
        { withCredentials: true }
      );
      setUser(response.data.data.user);
      const sessionToken = response.data.data.token;
      if (sessionToken) {
        localStorage.setItem('sessionToken', sessionToken);
      }
      setIsAuthenticated(true);
    } catch (err) {
      setIsAuthenticated(false);
      setUser(false);
    } finally {
      setLoader(false);
    }
  };

  const refreshUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_SERVICE_URL}/refreshUser`,
        { withCredentials: true }
      );
      setUser(response.data.data.user);
      const sessionToken = response.data.data.token;
      if (sessionToken) {
        localStorage.setItem('sessionToken', sessionToken);
      }
      setIsAuthenticated(true);
    } catch (err) {
      setIsAuthenticated(false);
      setUser(false);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    extractUserData();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, loader, refreshUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};
