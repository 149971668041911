import React, { useEffect, useState } from 'react';
import Logo from '../../assets/landingpage.png';
import widgetfn from '../../assets/widgetfn.png';
import axios from 'axios';
import TinyLoader from '../ui/TinyLoader/TinyLoader';
import VideoModal from '../ui/VideoModal/VideoModal';

const smoothScroll = (portion) => {
  const element = document.getElementById(portion);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const LandingPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [contactHeading, setContactHeading] = useState('Get In Touch');
  const [contactText, setContactText] = useState(
    'Have a question or need help getting started? Contact us today.'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const formSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = {
        name,
        email,
        message,
      };
      await axios.post(process.env.REACT_APP_AUTH_SERVICE_CONTACT_URL, data);
      setContactHeading('Message sent successfully 😎');
      setContactText(
        'Someone from featurenest will get in touch with you shortly'
      );
    } catch (error) {
      setContactHeading('Message could not be sent 😔');
      setContactText('we are fixing this issue, please try again in a bit');
    } finally {
      setLoading(false);
      setApiResponse(true);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_FEATURE_NEST_CDN_WIDGET;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div class="flex flex-col min-h-[100dvh]">
      <header class="px-4 lg:px-6 h-14 flex items-center">
        {/* <a class="flex items-center justify-center" href="#">
          <h3 class="text-xl font-bold">featureNest.co</h3>
        </a> */}
        <nav class="mr-auto hidden lg:flex gap-4 sm:gap-6">
          <button
            class="text-sm font-medium hover:underline underline-offset-4"
            data-feature-nest-project-id={
              process.env.REACT_APP_FEATURE_NEST_PROJECT_ID
            }
          >
            Raise a FR
          </button>
          <a
            class="text-sm font-medium hover:underline underline-offset-4"
            href="https://github.com/bucky-fullerene/featurenest-widget-pub"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to use?
          </a>
        </nav>
        <nav class="ml-auto hidden lg:flex gap-4 sm:gap-6">
          <a
            class="text-sm font-medium hover:underline underline-offset-4"
            href="#features"
            onClick={(event) => {
              event.preventDefault();
              smoothScroll('features');
            }}
          >
            Features
          </a>
          <a
            class="text-sm font-medium hover:underline underline-offset-4"
            href="#pricing"
            onClick={(event) => {
              event.preventDefault();
              smoothScroll('pricing');
            }}
          >
            Pricing
          </a>
          <a
            class="text-sm font-medium hover:underline underline-offset-4"
            href="#contact"
            onClick={(event) => {
              event.preventDefault();
              smoothScroll('contact');
            }}
          >
            Contact
          </a>
        </nav>
      </header>
      <main class="flex-1">
        <section className="w-full py-4 md:py-8 lg:py-12 xl:py-16 bg-[#fcf2f4]">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left">
              {/* Left content */}
              <div className="lg:w-1/2 lg:pr-8 lg:ml-8 space-y-4">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900">
                  Collect Feature Requests.
                </h1>
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900">
                  Build what Matters.
                </h1>
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900">
                  Delight your customers.
                </h1>
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mb-2">
                  Collect feature requests with a simple widget. Dive deep with
                  the dashboard. <br />
                  Add features that your customers want.
                </p>
                <div className="mt-6 flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 items-center justify-center lg:justify-start">
                  <a
                    className="inline-flex h-10 items-center justify-center rounded-md bg-[#fb7185] px-8 text-sm font-bold text-black shadow transition-colors hover:bg-[#fb7185]/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/signup`}
                  >
                    Get Started for Free
                  </a>

                  <button
                    className="inline-flex h-10 items-center justify-center rounded-md bg-[#34D399] px-8 text-sm font-bold text-black shadow transition-colors hover:bg-[#34D399]/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                    onClick={openModal}
                  >
                    Watch Demo (20s)
                  </button>

                  <VideoModal isOpen={isModalOpen} onClose={closeModal} />
                </div>
              </div>
              <div className="lg:w-1/2 mt-8 lg:mt-0">
                <img
                  src={Logo}
                  alt="Features"
                  className="h-[300px] w-[300px] md:h-[400px] md:w-[400px] lg:h-[500px] lg:w-[500px] object-contain mx-auto lg:ml-auto"
                />

                <div className="mt-2 flex justify-center lg:justify-center">
                  <a
                    href="https://fazier.com/launches/featurenest"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fazier.com/api/v1/public/badges/embed_image.svg?launch_id=1100&badge_type=daily"
                      width="270"
                      alt="FeatureNest Badge"
                      className="p-2 rounded"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="features"
          class="w-full py-12 md:py-24 lg:py-32 bg-[#F1F5F9]"
        >
          <div class="container mx-auto px-4 md:px-6">
            <div class="flex flex-col items-center justify-center space-y-4 text-center">
              <div class="space-y-2">
                <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                  Key Features
                </h2>
                <p class="max-w-[700px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  featureNest.co provides the tools you need to collect,
                  organise, and act on feature requests raised by your customer.
                </p>
              </div>
            </div>
            <div class="mx-auto grid max-w-5xl items-start gap-6 py-12 lg:grid-cols-2 lg:gap-12">
              <div class="flex flex-col justify-center space-y-4">
                <div class="grid gap-1">
                  <h3 class="text-xl font-bold">Feature Request Repository</h3>
                  <p class="text-muted-foreground">
                    Centralize, prioritize, and manage all your feature requests
                    in one organized repository.
                  </p>
                </div>
                <div class="grid gap-1">
                  <h3 class="text-xl font-bold">Feature Nest Widget</h3>
                  <p class="text-muted-foreground">
                    Integrate the Feature Nest Widget with just 2 lines of code
                    and start collecting feature requests instantly.
                  </p>
                </div>
                <div class="grid gap-1">
                  <h3 class="text-xl font-bold">
                    Streamlined Feature Request Management
                  </h3>
                  <p class="text-muted-foreground">
                    Categorize Feature Requests on the dashboard for efficient
                    prioritization and tracking.
                  </p>
                </div>
                <div class="grid gap-1">
                  <h3 class="text-xl font-bold">Collaboartive Sharing</h3>
                  <p class="text-muted-foreground">
                    Easily share and discuss feature requests with your
                    colleagues via email, enabling smooth team collaboration.
                  </p>
                </div>
              </div>
              <img
                src={widgetfn}
                alt="Features"
                class="mx-auto h-96 w-96 overflow-hidden rounded-xl object-contain -mt-12"
              />
            </div>
          </div>
        </section>
        <section id="pricing" class="w-full py-12 md:py-24 lg:py-32">
          <div class="container mx-auto px-4 md:px-6">
            <div class="flex flex-col items-center justify-center space-y-4 text-center">
              <div class="space-y-2">
                <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                  Pricing
                </h2>
                <p class="max-w-[700px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Choose the plan that best fits your business needs.
                </p>
              </div>
            </div>
            <div class="mx-auto grid max-w-5xl items-start gap-6 py-12 lg:grid-cols-3 lg:gap-12">
              <div class="flex flex-col justify-between rounded-lg border bg-background p-6 shadow-sm">
                <div class="space-y-2">
                  <h3 class="text-2xl font-bold">trialNest</h3>
                  <p class="text-4xl font-bold">Free</p>
                  <p class="text-muted-foreground">10 days</p>
                </div>
                <div class="space-y-2">
                  <ul class="grid gap-2 text-sm text-muted-foreground">
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Unlimited feature requests
                    </li>
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Up to 10 projects
                    </li>
                    {/* <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      1 user
                    </li> */}
                  </ul>
                  <a
                    class="inline-flex h-10 items-center justify-center rounded-md bg-[#fb7185] px-8 text-sm font-bold text-primary-foreground shadow transition-colors hover:bg-[#fb7185]/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/signup`}
                  >
                    Get Started
                  </a>
                </div>
              </div>
              <div class="flex flex-col justify-between rounded-lg border bg-background p-6 shadow-sm">
                <div class="space-y-2">
                  <h3 class="text-2xl font-bold">standardNest</h3>
                  <p class="text-4xl font-bold">$4.99</p>
                  <p class="text-muted-foreground">per month</p>
                </div>
                <div class="space-y-2">
                  <ul class="grid gap-2 text-sm text-muted-foreground">
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Unlimited feature requests
                    </li>
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Up to 10 projects
                    </li>
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Single User Per Account
                    </li>
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Share Feature Requests Over Email
                    </li>
                  </ul>
                  <a
                    class="inline-flex h-10 items-center justify-center rounded-md bg-[#fb7185] px-8 text-sm font-bold text-primary-foreground shadow transition-colors hover:bg-[#fb7185]/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/signup`}
                  >
                    Get Started
                  </a>
                </div>
              </div>
              <div class="flex flex-col justify-between rounded-lg border bg-background p-6 shadow-sm">
                <div class="space-y-2">
                  <h3 class="text-2xl font-bold">proNest</h3>
                  <p class="text-4xl font-bold">$14.99</p>
                  <p class="text-muted-foreground">per month</p>
                </div>
                <div class="space-y-2">
                  <ul class="grid gap-2 text-sm text-muted-foreground">
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Unlimited Feature Requests
                    </li>
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Unlimited Projects
                    </li>
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Up to 5 Users Per Account
                    </li>
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Share Feature Requests Over Email
                    </li>
                    <li class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="h-4 w-4 text-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                      Deduplication of Similar Feature Requests Using AI
                    </li>
                  </ul>
                  <button
                    class="inline-flex h-10 items-center justify-center rounded-md bg-[#fb7185] px-6 text-sm font-bold text-primary-foreground shadow transition-colors hover:bg-[#fb7185]/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    href="#"
                    disabled
                    aria-disabled="true"
                  >
                    coming soon!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="contact"
          class="w-full py-12 md:py-24 lg:py-32 bg-[#F1F5F9]"
        >
          <div class="container mx-auto px-4 md:px-6">
            <div class="flex flex-col items-center justify-center space-y-4 text-center">
              <div class="space-y-2">
                <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                  {contactHeading}
                </h2>
                <p class="mx-auto max-w-[700px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  {contactText}
                </p>
              </div>
              {!apiResponse && (
                <div class="mx-auto w-full max-w-sm space-y-4">
                  <form class="flex flex-col gap-4" onSubmit={formSubmit}>
                    <input
                      class="h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      class="h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <textarea
                      class="min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    {!loading ? (
                      <button
                        class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 text-primary-foreground h-10 px-4 py-2 bg-[#fb7185] hover:bg-[#fb7185]/90"
                        type="submit"
                      >
                        submit
                      </button>
                    ) : (
                      <div className="flex justify-center items-center w-full h-full">
                        <TinyLoader />
                      </div>
                    )}
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <footer class="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p class="text-xs text-muted-foreground">
          © 2024 featureNest.co All rights reserved.
        </p>
        <nav class="sm:ml-auto flex gap-4 sm:gap-6">
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/tos"
          >
            Terms and Conditions
          </a>
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/privacy"
          >
            Privacy Policy
          </a>
          <a
            class="text-xs hover:underline underline-offset-4"
            href="/help/refundpolicy"
          >
            Refund Policy
          </a>
        </nav>
      </footer>
    </div>
  );
};

export default LandingPage;
