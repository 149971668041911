import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../context/AuthContext';
import TinyLoader from '../TinyLoader/TinyLoader';

const priceIds = {
  stdNest: process.env.REACT_APP_PADDLE_STD_NEST_PRICE_ID,
  proNest: process.env.REACT_APP_PADDLE_PRO_NEST_PRICE_ID,
};

const itemsList = [
  {
    priceId: '',
    quantity: 1,
  },
];

const PaymentButton = () => {
  const { user, refreshUserData } = useAuth();
  const [showOptions, setShowOptions] = useState(false);
  const [loader, setLoader] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleBuyButtonClick = (priceId) => {
    itemsList[0].priceId = priceId;
    window.Paddle.Checkout.open({
      items: itemsList,
      customer: {
        email: user.email,
      },
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'prod') {
      window.Paddle.Environment.set('sandbox');
    }
    window.Paddle.Initialize({
      token: process.env.REACT_APP_PADDLE_TOKEN,
      eventCallback: function (data) {
        if (data.name === 'checkout.completed') {
          setLoader(true);
          // refreshUserData();
          setTimeout(() => {
            refreshUserData();
            setLoader(false);
          }, 60000);
        }
      },
    });
  }, []);

  if (loader) {
    return <TinyLoader />;
  }

  return (
    <div className="relative inline-block">
      <button
        onClick={toggleOptions}
        className="btn w-12 h-12 rounded-full bg-[#F74B14] text-white flex items-center justify-center"
      >
        <FontAwesomeIcon
          icon={faCartShopping}
          size="lg"
          style={{ color: 'black' }}
        />
      </button>
      <div
        className={`absolute top-[-120px] left-0 mt-2 flex flex-col gap-2 ${
          showOptions ? '' : 'hidden'
        }`}
      >
        <button
          class="rounded-lg bg-[#E4A0F9] text-xs text-black font-bold hover:bg-gray-200 px-4 py-2"
          onClick={() => handleBuyButtonClick(priceIds['stdNest'])}
        >
          stdNest-5$/month
        </button>
        <button
          className="rounded-lg bg-[#E4A0F9] text-xs text-black font-bold hover:bg-gray-200 px-4 py-2 disabled:bg-gray-400 disabled:cursor-not-allowed"
          onClick={() => handleBuyButtonClick(priceIds['proNest'])}
          disabled
        >
          proNest-15$/month
        </button>
      </div>
    </div>
  );
};

export default PaymentButton;
