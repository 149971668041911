import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ClientPage.module.css';
import List from '../ui/List/List';
import Footer from '../ui/Footer/Footer';
import Navbar from '../ui/Navbar/Navbar';
import Collapse from '../ui/Collapse/Collapse';
import Pagination from '../ui/Pagination/Pagination';
import Modal from '../ui/Modal/Modal';
import axiosInstance from '../../services/api/axiosInstance';
import { useAuth } from '../../context/AuthContext';
import TinyBanner from '../ui/TinyBanner/TinyBanner';
import EmptyState from '../ui/EmptyState/EmptyState';
import Masakali from '../../assets/MasakaliNoFr.jpeg';
import MasakaliNoPr from '../../assets/MasakaliNoPr.jpeg';
import MasakaliTrialOver from '../../assets/masakaliTrialOver.jpeg';
import Tooltip from '../ui/Tooltip/Tooltip';
import PaymentButton from '../ui/PaymentButton/PaymentButton';
import TinyLoader from '../ui/TinyLoader/TinyLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

const categoryIndex = {
  pending: 0,
  valuable: 1,
  future: 2,
  done: 3,
  trash: 4,
};

const determineMaxPage = (volumeOfFeatureRequests) => {
  return volumeOfFeatureRequests === 0
    ? 1
    : Math.ceil(volumeOfFeatureRequests / 5);
};

const timestampToLocalDateConverter = (timestamp) => {
  const date = new Date(timestamp);
  const locale = navigator.language;
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  const formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(
    date
  );
  const formattedTime = new Intl.DateTimeFormat(locale, timeOptions).format(
    date
  );
  return `${formattedDate}, ${formattedTime}`;
};

const ClientPage = () => {
  const { user } = useAuth();
  const clientId = user._id;
  const trialPeriodOverStatus = user.trialOver;
  const [projectList, setProjectList] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [filterStats, setFilterStats] = useState([0, 0, 0, 0, 0]);
  const [featureRequestList, setFeatureRequestList] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('pending');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMaxPage, setCurrentMaxPage] = useState(1);
  const [noProjectState, setNoProjectState] = useState(true);
  const [noFeatureRequests, setNoFeatureRequests] = useState(true);
  const [projectLoader, setProjectLoader] = useState(false);
  const [featureRequestLoader, setFeatureRequestLoader] = useState(false);
  const [maxProjectLengthReached, setMaxProjectLengthReached] = useState(false);

  const getFilterStats = async (projectId) => {
    try {
      const filterResponseStats = await axiosInstance.get(
        `/projects/${projectId}/featurerequest/stats`
      );
      setFilterStats(
        filterResponseStats.data.data.categories.map((type) => type.count)
      );
    } catch (err) {
      setFilterStats([0, 0, 0, 0, 0]);
    }
  };
  const getFeatureRequests = async (projectId, category, page = 1) => {
    try {
      const featureRequestsResponse = await axiosInstance.get(
        `/projects/${projectId}/featurerequest?category=${category}&page=${page}`
      );
      const featureRequestsBackend =
        featureRequestsResponse.data.data.featureRequests;
      if (featureRequestsBackend.length === 0) {
        setFeatureRequestList([]);
        setNoFeatureRequests(true);
        return;
      }
      const dateConvertedFeatureRequests = featureRequestsBackend.map(
        (featureRequest) => ({
          ...featureRequest,
          createdAt: timestampToLocalDateConverter(featureRequest.createdAt),
        })
      );
      setNoFeatureRequests(false);
      setFeatureRequestList(dateConvertedFeatureRequests);
    } catch (err) {
      console.error(err);
      setNoFeatureRequests(true);
      setFeatureRequestList([]);
    } finally {
    }
  };
  const getProjectList = async () => {
    try {
      setProjectLoader(true);
      const projectListResponse = await axiosInstance.get(
        `/clients/${clientId}/projects`
      );

      if (projectListResponse.data.data.projectList.length !== 0) {
        setNoProjectState(false);
      }
      if (projectListResponse.data.data.projectList.length >= 10) {
        setMaxProjectLengthReached(true);
      }
      const projects = projectListResponse.data.data.projectList.map(
        (project) => {
          const { _id, projectName } = project;
          return { _id, projectName };
        }
      );
      setProjectList(projects);
    } catch (err) {
      console.error(err);
      setProjectList([]);
    } finally {
      setProjectLoader(false);
    }
  };
  const handleDropdownSelect = async (project) => {
    setFeatureRequestLoader(true);
    await getFilterStats(project._id);
    await getFeatureRequests(project._id, 'pending');
    setProjectId(project._id);
    setProjectName(project.projectName);
    setCurrentCategory('pending');
    setCurrentPage(1);
    setFeatureRequestLoader(false);
  };

  const handleRefresh = async () => {
    setFeatureRequestLoader(true);
    await getFilterStats(projectId);
    await getFeatureRequests(projectId, currentCategory);
    setCurrentPage(1);
    setFeatureRequestLoader(false);
  };

  const handleCategorySelect = async (selectedCategory) => {
    setFeatureRequestLoader(true);
    setCurrentPage(1);
    setCurrentMaxPage(
      determineMaxPage(filterStats[categoryIndex[selectedCategory.text]])
    );
    setCurrentCategory(selectedCategory.text);
    await getFeatureRequests(projectId, selectedCategory.text);
    await getFilterStats(projectId);
    setFeatureRequestLoader(false);
  };
  const handleCategoryUpdate = () => {
    getFilterStats(projectId);
    getFeatureRequests(projectId, currentCategory);
  };
  const handlePageFlips = (direction) => {
    const newPage = direction === 'forward' ? currentPage + 1 : currentPage - 1;
    setCurrentPage(newPage);
    getFeatureRequests(projectId, currentCategory, newPage);
  };
  const handleProjectCreation = () => {
    getProjectList();
  };

  useEffect(() => {
    const fetchProjectList = async () => {
      await getProjectList();
    };
    fetchProjectList();
  }, [user]);
  useEffect(() => {
    setCurrentMaxPage(determineMaxPage(filterStats[0]));
  }, [filterStats]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Navbar
          projectList={projectList}
          onDropdownSelect={handleDropdownSelect}
        ></Navbar>
      </header>
      <div>
        {!trialPeriodOverStatus ? (
          <>
            {noProjectState ? (
              <div className={`${styles.emptyState}`}>
                {projectLoader ? (
                  <TinyLoader />
                ) : (
                  <EmptyState
                    image={MasakaliNoPr}
                    mainHeading="🕊Masakali could not find any projects 😕 "
                    subHeading="she now requests you to create your first project 🙏🏻"
                  />
                )}
              </div>
            ) : (
              <div className={styles.mainContent}>
                <div className={styles.heading}>
                  <h1>Feature Requests </h1>
                  <Tooltip message="refresh" placement="bottom">
                    <div className="cursor-pointer" onClick={handleRefresh}>
                      <FontAwesomeIcon
                        icon={faArrowsRotate}
                        size="lg"
                        style={{ color: '#094f1e' }}
                        className="mr-2"
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className={styles.content}>
                  <div className={styles.filter}>
                    <div>
                      <TinyBanner projectId={projectId} />
                    </div>
                    <div>
                      <List
                        filterNumbers={filterStats}
                        projectId={projectId}
                        onCategorySelect={handleCategorySelect}
                      />
                    </div>
                    <div className="mt-4 ml-12">
                      <Pagination
                        page={currentPage}
                        onPageFlip={handlePageFlips}
                        maxPage={currentMaxPage}
                      />
                    </div>
                  </div>
                  <div className={styles.featureRequests}>
                    {featureRequestLoader ? (
                      <div className="flex justify-center items-center">
                        <TinyLoader />
                      </div>
                    ) : noFeatureRequests ? (
                      <EmptyState
                        image={Masakali}
                        mainHeading="🕊Masakali could not find any feature Requests 😕"
                        subHeading="maybe your project is too perfect right now 😉"
                      />
                    ) : (
                      featureRequestList.map((featureItem, index) => (
                        <Collapse
                          key={index}
                          feature={featureItem}
                          onCategoryUpdate={handleCategoryUpdate}
                          categoryPage={currentCategory}
                          projectName={projectName}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}
            {!maxProjectLengthReached && (
              <div className={styles.modalWrapper}>
                <Tooltip message="add a project" placement="top">
                  <div>
                    <Modal
                      clientId={clientId}
                      onProjectCreation={handleProjectCreation}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </>
        ) : (
          <div>
            <EmptyState
              image={MasakaliTrialOver}
              mainHeading="your trial period just got over😕 "
              subHeading="if you wanna continue using featurenest, please subscribe 🙏🏻 "
            />
          </div>
        )}
      </div>
      {user.userCategory.category === 'free' && (
        <div className={styles.paymentButtonWrapper}>
          <PaymentButton />
        </div>
      )}
      <footer className={styles.footer}>
        <Footer></Footer>
      </footer>
    </div>
  );
};

export default ClientPage;
