import React, { useState } from 'react';
import SingleInputForm from '../ui/SingleInputForm/SingleInputForm';
import axios from 'axios';
import Footer from '../ui/Footer/Footer';
import styles from './LoginPage.module.css';

const LoginPage = () => {
  const [title, setTitle] = useState('login!');
  const [displayText, setDisplayText] = useState(
    '🕊 Masakali wants to know your email so that she can log you into featureNest'
  );
  const [buttonName, setButtonName] = useState('login');
  const [buttonClickStatus, setButtonClickStatus] = useState(false);

  const handleButtonSubmit = async (email) => {
    const data = JSON.stringify({
      email,
    });
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/loginUser`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
      setTitle('🕊 Masakali! ');
      setDisplayText(
        `sent you an email on ${email} with a link to login to our portal. please check your SPAM folder as well`
      );
      setButtonClickStatus(true);
    } catch (err) {
      if (
        err.response.data.error.message === 'please verify your credentials'
      ) {
        setTitle('🕊 Masakali! ');
        setDisplayText(
          `got upset 😭 since she could not find you in our records, please signup by clicking`
        );
        setButtonClickStatus(true);
      }
      if (
        err.response.data.error.message === 'please verify your registration'
      ) {
        setTitle('🕊 Masakali! ');
        setDisplayText(
          `found you in our records but observed that your email is not verified 😔, please verify yourself by signing up again`
        );
        setButtonClickStatus(true);
      }
      if (err.response.data.error.message === 'internal-server-error') {
        setTitle('🕊 Masakali! ');
        setDisplayText(
          `messed up a bit 😔, this was not supposed to happen. please try again after sometime`
        );
        setButtonClickStatus(true);
      }
    }
  };
  return (
    <div className={styles.loginPage}>
      <main className={styles.mainContent}>
        <SingleInputForm
          title={title}
          displayText={displayText}
          buttonName={buttonName}
          handleSubmit={handleButtonSubmit}
          buttonClickStatus={buttonClickStatus}
        />
      </main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </div>
  );
};

export default LoginPage;
